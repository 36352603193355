




















































import { defineComponent, toRefs, computed } from "@vue/composition-api"

import useStore from "@/use/useStore"
import useViewer from "./Games/JeopardAI/use/useViewer"
import useJeopardAiAnswers from "./Games/JeopardAI/use/useJeopardAiAnswers"
import useIsViewerHostLike from "./Games/JeopardAI/use/useIsViewerHostLike"
import useJeopardAiState from "./Games/JeopardAI/use/useJeopardAiState"
import useJeopardAiRound from "./Games/JeopardAI/use/useJeopardAiRound"
import useJeopardAiQueueRef from "./Games/JeopardAI/use/useJeopardAiQueueRef"
import useJeopardAiAnswersRef from "./Games/JeopardAI/use/useJeopardAiAnswersRef"
import useJeopardAiTeamId from "./Games/JeopardAI/use/useJeopardAiTeamId"
import { useConfirm } from "@/use"

import { Module } from "./Games/JeopardAI/enums"
import { push, serverTimestamp, update } from "firebase/database"

import { addCommasToNumber } from "./Games/JeopardAI/helpers"

import { MAX_CHALLENGES } from "./Games/JeopardAI/constants"
import useJeopardAiQueue from "./Games/JeopardAI/use/useJeopardAiQueue"

export default defineComponent({
  name: "JeopardAiTeamTag",
  components: {},
  props: {
    team: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { team } = toRefs(props)
    const { confirm } = useConfirm()
    const { store } = useStore()
    const { state } = useJeopardAiState()
    const { dedupedQueue } = useJeopardAiQueue(state)
    const { answers } = useJeopardAiAnswers()
    const { round } = useJeopardAiRound()
    const { ref: queueRef } = useJeopardAiQueueRef(round, state)
    const { ref: answersRef } = useJeopardAiAnswersRef()
    const { isViewerHostLike } = useIsViewerHostLike()
    const { viewer } = useViewer()
    const { teamId: activeTeamId } = useJeopardAiTeamId()
    const teamQueueItem = computed(() =>
      dedupedQueue.value.find(item => item.teamId === team.value.id)
    )

    const teamId = computed(() => team.value?.id)
    const length = computed(() => team.value.name?.length)
    const active = computed(() => teamId.value === activeTeamId.value)

    const challenges = computed(() => {
      if (
        state.value?.questions?.[state.value?.questionId]?.double &&
        state.value?.teamId !== team.value?.id
      )
        return []

      if (state.value?.module !== Module.ANSWERS) return []

      const current = answers.value.filter(
        answer =>
          answer.challenge === true &&
          answer.teamId === teamId.value &&
          answer.correct === false
      )

      const n = Math.min(current.length, MAX_CHALLENGES)

      const values = []

      let i: number

      for (i = 0; i < MAX_CHALLENGES; i++)
        values.push({
          id: i,
          used: false,
          available:
            viewer.value?.teamID === teamId.value || isViewerHostLike.value
        })

      for (i = 0; i < n; i++) {
        if (values[i] == null) continue
        values[i].used = true
      }

      return values
    })
    const total = computed(() => {
      if (isViewerHostLike.value === false) {
        const finalQuestionId = state.value?.finale?.id
        return answers.value.reduce((acc, val) => {
          if (val.teamId !== team.value?.id) return acc
          if (val.questionId === finalQuestionId && finalQuestionId != null)
            return acc
          if (val.amount != null) return acc + val.amount

          return acc
        }, 0)
      }

      return answers.value.reduce((acc, val) => {
        if (val.teamId !== team.value?.id) return acc
        if (val.amount != null) return acc + val.amount

        return acc
      }, 0)
    })
    const selectable = computed(() => isViewerHostLike.value)

    function increase() {
      if (isViewerHostLike.value === false) return
      const amount = Math.ceil(Math.abs(total.value) / 2) || 1
      const value = {
        timestamp: serverTimestamp(),
        userId: viewer.value?.id,
        round: round.value,
        teamId: team.value?.id,
        amount
      }
      push(answersRef.value, value)
    }

    function decrease() {
      if (isViewerHostLike.value === false) return
      const amount = (Math.ceil(Math.abs(total.value) / 2) || 1) * -1
      const value = {
        timestamp: serverTimestamp(),
        userId: viewer.value?.id,
        round: round.value,
        teamId: team.value?.id,
        amount
      }
      push(answersRef.value, value)
    }

    function select() {
      store.dispatch("group/updateCurrentGlobalTeam", team.value?.id)
    }

    async function challenge() {
      const response = await confirm({
        message: `Would you like to challenge JeopardAI?`,
        title: "System"
      })

      if (response === false) return

      update(
        answersRef.value,
        answers.value.reduce((acc, val) => {
          if (val.teamId !== team.value.id) return acc
          if (val.questionId !== state.value.questionId) return acc

          acc[val.id] = null
          return acc
        }, {})
      )

      const userId = isViewerHostLike.value
        ? teamQueueItem.value?.userId
        : viewer.value?.id
      const teamId = isViewerHostLike.value
        ? teamQueueItem.value?.teamId ?? null
        : viewer.value?.teamID ?? null

      console.log({ userId, teamId })
      push(queueRef.value, {
        timestamp: serverTimestamp(),
        userId,
        teamId,
        challenge: true
      })
    }

    const wrong = computed(() => {
      if (state.value?.module === Module.FINALE) return false
      return answers.value.some(
        answer =>
          answer.teamId === team.value?.id &&
          !answer.correct &&
          answer.questionId &&
          answer.questionId === state.value?.questionId
      )
    })

    const correct = computed(() => {
      if (state.value?.module === Module.FINALE) return false
      return answers.value.some(
        answer =>
          answer.teamId === team.value?.id &&
          answer.correct &&
          answer.questionId === state.value?.questionId
      )
    })

    return {
      teamQueueItem,
      addCommasToNumber,
      increase,
      decrease,
      correct,
      wrong,
      total,
      select,
      selectable,
      challenges,
      challenge,
      answers,
      length,
      active
    }
  }
})
