var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "jeopardai-team-tag",
      class:
        ((_obj = {}),
        (_obj["jeopardai-team-tag--selectable"] = _vm.selectable),
        (_obj["jeopardai-team-tag--active"] = _vm.active),
        (_obj["jeopardai-team-tag--correct"] = _vm.correct),
        (_obj["jeopardai-team-tag--wrong"] = _vm.wrong),
        _obj),
    },
    [
      _c(
        "div",
        { staticClass: "jeopardai-team-tag__icon mr-2" },
        [_c("v-icon", [_vm._v(" " + _vm._s(_vm.team.icon) + " ")])],
        1
      ),
      _c(
        "div",
        {
          staticClass: "jeopardai-team-tag__title mr-2",
          class:
            ((_obj$1 = {}),
            (_obj$1["jeopardai-team-tag__title--small"] = _vm.length > 15),
            _obj$1),
          on: {
            click: function ($event) {
              _vm.selectable && _vm.select()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.team.name) + " ")]
      ),
      _vm.total !== null
        ? _c(
            "div",
            {
              key: _vm.total,
              staticClass: "jeopardai-team-tag__total",
              on: {
                click: [
                  function ($event) {
                    if (!$event.shiftKey) {
                      return null
                    }
                    return _vm.increase()
                  },
                  function ($event) {
                    if (!$event.altKey) {
                      return null
                    }
                    return _vm.decrease()
                  },
                  function ($event) {
                    if (!$event.ctrlKey) {
                      return null
                    }
                    return _vm.decrease()
                  },
                ],
              },
            },
            [_vm._v(" $" + _vm._s(_vm.addCommasToNumber(_vm.total)) + " ")]
          )
        : _vm._e(),
      _vm.challenges.length > 0
        ? _c(
            "div",
            _vm._l(_vm.challenges, function (item) {
              var _obj
              return _c("i", {
                key: item.id,
                staticClass:
                  "mdi mdi-sword-cross jeopardai-team-tag__challenge-ai ml-2",
                class:
                  ((_obj = {}),
                  (_obj["jeopardai-team-tag__challenge-ai--used"] = item.used),
                  (_obj["jeopardai-team-tag__challenge-ai--available"] =
                    item.available),
                  _obj),
                on: {
                  click: function ($event) {
                    item.available && !item.used && _vm.challenge()
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }